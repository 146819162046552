import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet";
import Layout from "../layouts"
import Seo from "../components/seo"
import {BreadCrumb, SectionPageTitle, SectionDescription } from "../components/Section"
import GrayWoodSection from "../components/Section/GrayWoodSection"
import CtaSection from '../sections/CtaSection';

const InfographicsPage = ({ location }) => {
	return(
		<Layout footerCta location={location}>
			<Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
			<Seo title="Infographics on new trending topics - Buildings And More" description="Here are some interesting infographics related to outdoor buildings and other topics that might interest you." />
			<BreadCrumb position="relative">
				<div className="container">
					<Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
					<span>Infographics</span>
				</div>
			</BreadCrumb>
			<GrayWoodSection>
				<div className="container">
					<SectionPageTitle>Infographics</SectionPageTitle>
					<SectionDescription maxWidth="950px" mb="35px">
						Coming Soon
					</SectionDescription>
				</div>
			</GrayWoodSection>
			<CtaSection />
		</Layout>
	)
}

export default InfographicsPage
